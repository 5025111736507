import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoginPage from './components/auth/LoginPage';
import RegistrationPage from './components/auth/RegistrationPage';
import NotAuthorised from './components/common/NotAuthorised';
import PageNotFound from './components/common/PageNotFound';
import AuthDetailContext from './context/AuthDetailContext';
import DashboardPage from './components/DashboardPage';
import DashOutlet from './components/common/DashOutlet';
import RequireAuth from './components/auth/RequireAuth';
import InvoicePage from './components/invoices/InvoicePage';
import BillsPage from './components/bills/BillsPage';
import MarketPage from './components/market/MarketPage';
import InvoiceDetailPage from './components/invoices/InvoiceDetailPage';
import BillDetailPage from './components/bills/BillDetailPage';
import ListingDetailPage from './components/market/ListingDetailPage';
import WarehousePage from './components/market/WarehousePage';
import RegistrationSuccessPage from './components/auth/RegistrationSuccessPage';
import WalletPage from './components/wallet/WalletPage';
import WalletSettingsPage from './components/wallet/WalletSettingsPage';



function App() {
  const queryClient = new QueryClient({});
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    getCurrentUser();
  },[]);

  const getCurrentUser = () => {
      let profile = JSON.parse(localStorage.getItem("profile"));
      setCurrentUser(profile);
      
  }
  return (
    <>
      <QueryClientProvider client={queryClient}>
    <AuthDetailContext.Provider value={currentUser}>
      <div className="App">
      <Routes>
      <Route
                    path="/"
                    element={
                      <RequireAuth allowedRoles={["*"]}>
                        <DashOutlet />
                      </RequireAuth>
                    }
                  >
                     <Route path="" element={<DashboardPage />} />
                     <Route path="invoices" element={<InvoicePage />} />
                     <Route path="invoices/:id" element={<InvoiceDetailPage />} />
                     <Route path="bills" element={<BillsPage />} />
                     <Route path="bills/:id" element={<BillDetailPage />} />
                     <Route path="market" element={<MarketPage />} />
                     <Route path="market/:id" element={<ListingDetailPage />} />
                     <Route path="warehouse" element={<WarehousePage />} />
                     <Route path="wallet" element={<WalletPage />} />
                     <Route path="wallet/settings/" element={<WalletSettingsPage />} />
                   
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegistrationPage />} />
        <Route path="/signup/2/:company" element={<RegistrationSuccessPage />} />
        <Route path="/signup/success" element={<RegistrationSuccessPage />} />
        
          <Route path="403" element={<NotAuthorised />} />
              <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
    </AuthDetailContext.Provider>
    </QueryClientProvider>
    </>
    
  );
}

export default App;
