import React from 'react'
import { Table } from 'react-bootstrap';
import { formatDate, formatNumber } from '../../../utils/helper_functions';
import { useQuery } from '@tanstack/react-query';
import { fetchWalletStatement } from '../../../services/wallet/account-services';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';

function MiniStatement(props) {
    const { isLoading, isError, error, data:transactions, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["wallet-statement", props.wallet],
      queryFn: () => fetchWalletStatement(props.wallet),
    });
  return (
    <>
        {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<>
      <Table responsive hover>
          <thead>
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Narration</th>
                <th>category</th>
            </tr>
          </thead>
          <tbody>
            {transactions.data.map((item, i) => (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{formatDate(item.created)}</td>
                    <td>{formatNumber(item.value_num)}</td>
                    <td>{item.txn.description}</td>
                    <td>{item.txn.category}</td>
                </tr>
            ))}
            
          </tbody>
      </Table>
      
      </>)}
    </>
  )
}

export default MiniStatement