import axiosAPI from "../axiosApi";

export async function createAttachment(invoice, payload) {
    axiosAPI.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    const response = await axiosAPI.post(`vault/invoices/${invoice}/attachments/`, payload, {headers:{
      'Content-Type': 'multipart/form-data'
    }});
    return response;
  }

export async function fetchAttachments(invoice, params = {}) {
  const response = await axiosAPI.get(`vault/invoices/${invoice}/attachments/`, { params: params });
  return response;
}

export async function fetchAttachmentById(invoice, id) {
  const response = await axiosAPI.get(`vault/invoices/${invoice}/attachments/${id}/`);
  return response;
}


export async function updateAttachmentById(invoice, id, payload) {
  const response = await axiosAPI.put(`vault/invoices/${invoice}/attachments/${id}/`, payload,{headers:{
    'Content-Type': 'multipart/form-data'
  }});
  return response;
}


export async function deleteInvoiceById(invoice, id) {
    const response = await axiosAPI.delete(`vault/invoices/${invoice}/attachments/${id}/`);
    return response;
}