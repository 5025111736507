import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function ClientNavLinks() {
  return (
    <>
          <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/">
                Dashboard
              </Nav.Link>
              <Nav.Link as={NavLink} to="/invoices">
                Receivables
              </Nav.Link>
              <Nav.Link as={NavLink} to="/bills">
                Payables
              </Nav.Link>
              <Nav.Link as={NavLink} to="/wallet">
                Account
              </Nav.Link>
            </Nav>
    </>
  )
}

export default ClientNavLinks