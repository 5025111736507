import React from 'react'
import { fetchBills } from '../../../services/invoices/bill-services';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from '../../../utils/helper_functions';
import StatusBadge from '../../widgets/StatusBadge';

function BillList(props) {
    const { isLoading, isError, error, data:invoices, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["bill-list"],
      queryFn: () => fetchBills({ ...props.filters }),
      keepPreviousData: false
    });
  return (
    <>
    {isLoading ? (
    <LoadingSpinner />
      ) : isError ? (
        <ErrorAlert>Error: {error.message}</ErrorAlert>
      ) : (<>
            <Table hover striped responsive>
              <thead>
                <tr>
                    <th>#</th>
                    <th>InvoiceNo</th>
                    <th>Amount</th>
                    <th>Issue Date</th>
                    <th>Issuer</th>
                    
                    <th>Due Date</th>
                    <th>PayDate</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {invoices.data.results.map((item, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                            <Link to={`${item.id}`}>{item.invoice_ref}</Link></td>
                        <td>{formatNumber(item.amount)}</td>
                        <td>{formatDate(item.issue_date)}</td>
                        <td>{item.issuer.name}</td>
                        
                        <td>{formatDate(item.due_date)}</td>
                        <td>{formatDate(item.pay_date)}</td>
                        <td><StatusBadge value={item.status} /></td>
                        <td>:</td>
                    </tr>
                ))}
              </tbody>
              </Table>
            </>)}
</>
  )
}

export default BillList