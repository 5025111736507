import React, { useState } from 'react'
import { fetchInvoiceById, sendInvoiceById } from '../../services/invoices/invoice-services';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Stack, Tab, Table, Tabs } from 'react-bootstrap';
import LoadingSpinner from '../widgets/loadingSpinner';
import ErrorAlert from '../widgets/ErrorAlert';
import { formatDate, formatHttpErrorMessage, formatNumber, formatShortDate } from '../../utils/helper_functions';
import InvoiceEditForm from './widgets/InvoiceEditForm';
import InvoiceDiscountForm from './widgets/InvoiceDiscountForm';
import AttachmentTableList from './widgets/AttachmentTableList';
import PaymentTableList from './widgets/PaymentTableList';
import NoteDetail from '../market/widgets/NoteDetail';
import StatusBadge from '../widgets/StatusBadge';

function InvoiceDetailPage() {
    let {id} = useParams()
    const [showInvoiceEditForm, setShowInvoiceEditForm] = useState(false);
    const [showDiscountForm, setShowDiscountForm] = useState(false);
    const { isLoading, isError, error, data:invoice, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["invoice", id],
      queryFn: () => fetchInvoiceById(id),
    });

    const submitInvoiceToCustomer = async () => {
      await sendInvoiceById(id).then(response => {
        alert("invoice submitted to client")
      }).catch(error => {
         let msg = formatHttpErrorMessage(error)
         alert("Error: " + msg)
      })
    }
  return (
    <>

      <Row className='g-3'>
        <Col md={6}>
            <h2>Invoice Details</h2>
        </Col>
        <Col md={6}>
          <Stack direction="horizontal" gap={2} className='float-end'>
              <Button variant='secondary' onClick={() => setShowInvoiceEditForm(true)} disabled={invoice?.data.status != 'Draft'}>Edit</Button>
              <Button variant='primary' onClick={() => submitInvoiceToCustomer()} disabled={invoice?.data.status != 'Draft'}>Send</Button>
              <Button variant='success' onClick={() => setShowDiscountForm(true)} disabled={invoice?.data.status != 'Approved'}>Discount</Button>
              <Button variant='danger' disabled={invoice?.data.status != 'Draft'}>Delete</Button>
          </Stack>
          {showInvoiceEditForm && <InvoiceEditForm id={id} invoice={invoice?.data} show={showInvoiceEditForm} onHide={() => setShowInvoiceEditForm(false)} />}
          {showDiscountForm && <InvoiceDiscountForm id={id} show={showDiscountForm} onHide={() => setShowDiscountForm(false)} />}
        </Col>
        <Col md={12}>
          {isLoading ? (
            <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (
            <>
              <Card>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Invoice</th><td>{invoice.data.invoice_ref}</td></tr>
                                <tr><th>Amount</th><td>{formatNumber(invoice.data.amount)}</td></tr>
                                <tr><th>Purchase Ref</th><td>{invoice.data.po_ref}</td></tr>
                                <tr><th>Customer</th><td>{invoice.data.issued_to.name}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Date Issued</th><td>{formatDate(invoice.data.issue_date)}</td></tr>
                                <tr><th>Due date</th><td>{formatDate(invoice.data.due_date)}</td></tr>
                                <tr><th>Pay date</th><td>{formatDate(invoice.data.pay_date)}</td></tr>
                                <tr><th>Date paid</th><td>{formatDate(invoice.data.date_paid)}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Discounted</th><td>{invoice.data.is_note ? "Yes" :'No'}</td></tr>
                                <tr><th>Status</th><td><StatusBadge value={invoice.data.status}/></td></tr>
                                <tr><th>Updated</th><td>{formatShortDate(invoice.data.updated)}</td></tr>
                                <tr><th>Created</th><td>{formatShortDate(invoice.data.created)}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
              </Card>
            </>
          )}
        </Col>
        <Col md={12}>
        <Card className="mt-2">
          <Card.Body>
                    <Tabs
                      defaultActiveKey="home"
                      id="detail-tab"
                      className="mb-3"
                    >
                    <Tab eventKey="home" title="Attachments" className='p-3'>
                        <AttachmentTableList invoice={id}/>
                    </Tab>
                    <Tab eventKey="payments" title="Payments" className='p-3'>
                       <PaymentTableList />
                    </Tab>
                    <Tab eventKey="trades" title="Note Details" className='p-3'>
                         <NoteDetail  invoice={id} />
                    </Tab>

                    </Tabs>
                    </Card.Body>
            </Card>
     </Col>
      </Row>
    </>
  );
}

export default InvoiceDetailPage