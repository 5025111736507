import React from 'react'
import { fetchAssets } from '../../../services/market/asset-services';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { ProgressBar, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from '../../../utils/helper_functions';
import RatingBadge from './RatingBadge';

function Listings(props) {
    const { isLoading, isError, error, data:assets, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["assets"],
      queryFn: () => fetchAssets({ ...props.filters }),
      keepPreviousData: false
    });
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <ErrorAlert>Error: {error.message}</ErrorAlert>
      ) : (
        <>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Rating</th>
                <th>Listing ID</th>
                <th>Face value</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>Guarantor</th>
                <th>%Pledge</th>
                <th>Maturity date</th>
             
              </tr>
            </thead>
            <tbody>
                {assets.data.results.map((item, i)=>(
                    <tr key={i}>
                        <td><RatingBadge rating={item.rating} /></td>
                      <td><Link to={`${item.id}`}>{item.id}</Link></td>
                      <td>{formatNumber(item.face_value)}</td>
                      <td>{formatNumber(item.amount)}</td>
                      <td>{item.coupon_rate}</td>
                      <td>{item.guarantor}</td>
                      <td><ProgressBar now={(item.pledged/item.amount )*100} label={`${formatNumber((item.pledged/item.amount )*100)}%`} />
                        <small>{formatNumber(item.pledged)}</small>
                      </td>
                      <td>{formatDate(item.maturity_date)}</td>
                    
                   </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export default Listings