import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import ErrorAlert from '../../widgets/ErrorAlert'
import LinkedAccountSelect from './LinkedAccountSelect';
import { formatHttpErrorMessage, formatNumber } from '../../../utils/helper_functions';
import { createWithdrawRequest } from '../../../services/wallet/withdraw-services';
import LoadingSpinner from '../../widgets/loadingSpinner';

function WithdrawForm(props) {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState({
            "currency": "UGX",
            "category": "Bank",
            "amount": "",
            "account": ""
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        await createWithdrawRequest(formData).then(response => {
            props.onHide()
            setLoading(false)
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg)
            setLoading(false)
        })
        console.log(formData)
    }
  return (
    <>
    <Modal
   {...props}
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered
   backdrop="static"
   keyboard={false}
 >
   <Modal.Header closeButton>
     <Modal.Title id="contained-modal-title-vcenter" >
     Send Money to My Account
     </Modal.Title>
   </Modal.Header>
   <Modal.Body>
    <Card className='mb-2'>
        <Card.Body>
            Account Balance.
            <h3>{props.account.balance}</h3>
        </Card.Body>
        
    </Card>
    {loading ? <center><LoadingSpinner /></center>:(
   <Form onSubmit={handleSubmit}>
     {message && <ErrorAlert>{message}</ErrorAlert>}
     <Form.Group as={Row} className="mb-3">
          <Form.Label as="legend" column sm={6}>
            How would you like to receive the funds
          </Form.Label>
          <Col sm={6}>
            <Form.Check
              type="radio"
              label="Mobile Money"
              name="category"
              id="formCategoryMobile"
              value="Mobile"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              label="Bank"
              name="category"
              id="formCategoryBank"
              value="Bank"
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
     <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={6}>
          Amount you want to withdraw
        </Form.Label>
        <Col sm={6}>
          <Form.Control type="number" name="amount" value={formData.amount} onChange={handleChange} min={5000} max={props.account.balance} placeholder="e.g 1000" required/>
        </Col>
      </Form.Group>
      <Row>
        <Col md={12}>
        <Table>
            <tbody>
                <tr>
                    <th>Withdrawal Fees</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Platform Fees</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Total Amount you will Receive</th>
                    <td>{formatNumber(formData.amount)}</td>
                </tr>
            </tbody>
         </Table>
        </Col>
         
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="formAccount">
        <Form.Label column sm={6}>
          Send to this account
        </Form.Label>
        <Col sm={6}>
          <LinkedAccountSelect value={formData.account} name="account" onChange={handleChange} required />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 2 }} className="d-grid gap-2">
          <Button type="submit" variant='success'>Continue</Button>
        </Col>
      </Form.Group>
     </Form>)}
     </Modal.Body>
     </Modal>
     </>
  )
}

export default WithdrawForm