import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import {faker} from '@faker-js/faker'


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Transactions Line Chart',
      },
    },
  };
  
  const labels = ['Feb','Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Outgoing',
        data: labels.map(() => faker.datatype.number({ min: 50000, max: 8000000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Incoming',
        data: labels.map(() => faker.datatype.number({ min: 50000, max: 8000000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

function IncomeHistoryBarChart() {
  return (
    <div>
        <Row>
            <Col>
               <Card>
                 <Card.Header>Invoices Summary </Card.Header>
                 <Card.Body>
                        <Line options={options} data={data} />
                 </Card.Body>
               </Card>
            </Col>
        </Row>
    </div>
  )
}

export default IncomeHistoryBarChart