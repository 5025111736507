import { useLocation, Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../../services/auth/auth-service";
import UserLayout from "../common/UserLayout";

const RequireAuth = ({ children }) => {
    const location = useLocation();
    return isAuthenticated() ? (
        <>
        <UserLayout>
            {children}
        </UserLayout>
        </>) : <Navigate to="/login" replace/>
}

export default RequireAuth;