import axiosAPI from "../axiosApi";

export async function createWarehouse(payload) {
    const response = await axiosAPI.post("inventory/warehouse/", payload);
    return response;
  }

export async function fetchWarehouses(params = {}) {
  const response = await axiosAPI.get("inventory/warehouse/", { params: params });
  return response;
}

export async function fetchWarehouseById(id) {
  const response = await axiosAPI.get(`inventory/warehouse/${id}/`);
  return response;
}
export async function fetchUserDefaultWarehouse() {
    const response = await axiosAPI.get(`inventory/warehouse/default/`);
    return response;
  }

  export async function fetchWarehouseStock(id) {
    const response = await axiosAPI.get(`inventory/warehouse/${id}/stock_list/`);
    return response;
  }
export async function updateWarehouseById(id, payload) {
  const response = await axiosAPI.put(`inventory/warehouse/${id}/`, payload);
  return response;
}


export async function deleteWarehouseById(id) {
    const response = await axiosAPI.delete(`inventory/warehouse/${id}/`);
    return response;
}