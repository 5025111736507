import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Listings from './widgets/Listings'

function MarketPage() {
  return (
    <>
    <Row className='mt-3 mb-3'>
      <Col md={4}>
        <h2>Browse Listings</h2>
      </Col>
      <Col md={8}>
          Search By ID
      </Col>
    </Row>
      <Row className="g-2">
        <Col md={12}>
          <Card>
            <Card.Body>
              <Listings />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default MarketPage