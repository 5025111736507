import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Row, Stack } from 'react-bootstrap'
import LoadingSpinner from '../widgets/loadingSpinner'
import { formatDate, formatNumber } from '../../utils/helper_functions'
import { useQuery } from '@tanstack/react-query';
import { fetchPrimaryAccount } from '../../services/wallet/account-services';
import MiniStatement from './widgets/MiniStatement';
import { Link } from 'react-router-dom';
import WithdrawForm from './widgets/WithdrawForm';

function WalletPage() {
    const [showWithdrawForm, setShowWithDrawForm] = useState(false)
    const { isLoading, isError, error, data:wallet, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["wallet-primary"],
      queryFn: () => fetchPrimaryAccount(),
    });
  return (
    <>
         <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          
            <Breadcrumb.Item active>
              Wallet
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className='mb-2'>
        
        <Col md={8}>
         {isLoading ? <LoadingSpinner />: 
         (<>{isError ? <p>Error: {error}</p>:<p className='text-left'><strong>Your Balance is </strong> <small>UGX</small> <span style={{fontSize:'34px'}} className='title'>{formatNumber(wallet?.data?.balance)}</span> as of {formatDate(Date())}</p>}</>)} 
        </Col>
        <Col md={4}>
        <Stack direction="horizontal" gap={3} className="float-end">
        <Button
            variant="success"
            onClick={() => setShowWithDrawForm(true)}
          >
            Send Funds Now
          </Button>
        <Button
            variant="outline-success"
            as={Link}
            to="/wallet/settings/"
          >
            View Payment Settings
          </Button>
        </Stack>

        {showWithdrawForm && <WithdrawForm account={wallet?.data} show={showWithdrawForm} onHide={() => setShowWithDrawForm(false)} />}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
          <p className='p-2 border-bottom'>
                Recent transactions
                </p>
            <Card.Body>
                {wallet?.data?.id && <MiniStatement wallet={wallet?.data?.id} />}
            </Card.Body>
          </Card>
            
        </Col>
      </Row>
    </>
  )
}

export default WalletPage