import { useQuery } from '@tanstack/react-query';
import React from 'react'
import LoadingSpinner from '../widgets/loadingSpinner';
import ErrorAlert from '../widgets/ErrorAlert';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { fetchUserDefaultWarehouse, fetchWarehouseStock } from '../../services/inventory/warehouse-services';
import { formatNumber } from '../../utils/helper_functions';

function WarehousePage() {
    const { isLoading, isError, error, data:warehouse, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["warehouse"],
      queryFn: () => fetchUserDefaultWarehouse(),
    });
    const warehouseId = warehouse?.data.id;
    const { isLoading:loading, data:stock } =
    useQuery({
      queryKey: ["stock"],
      queryFn: () => fetchWarehouseStock(warehouse?.data.id),
      enabled: !!warehouse?.data.id,
    });
  return (
    <div>
        <Row>
        <Col md={4}>
                {isLoading ? (
                <LoadingSpinner />
                        ) : isError ? (
                            <ErrorAlert>Error: {error.message}</ErrorAlert>
                        ) : (
                <>
                  <Card>
                        <Card.Body>
                            <h3>{formatNumber(warehouse.data.estimated_value)}</h3>
                            <small>Interest Earned</small>
                        </Card.Body>
                  </Card>
                </>)}
            </Col>
            <Col md={4}>
                {isLoading ? (
                <LoadingSpinner />
                        ) : isError ? (
                            <ErrorAlert>Error: {error.message}</ErrorAlert>
                        ) : (
                <>
                  <Card>
                        <Card.Body>
                            <h3>{formatNumber(warehouse.data.estimated_value)}</h3>
                            <small>Total Investments</small>
                        </Card.Body>
                  </Card>
                </>)}
            </Col>
            
            <Col md={4}>
                <Card>
                        <Card.Body>
                            0
                            
                        </Card.Body>
                  </Card>
            </Col>
        </Row>
        <Row>
            <Col md={12} className='mt-3'>
                <Card>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Listing ID</th>
                            <th>garantor</th>
                            <th>Qty</th>
                            <th>Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading==false && stock?.data.length && stock?.data.map((item, i) => (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.asset}</td>
                                <td>{item.guarantor}</td>
                                <td>{item.qty}</td>
                                <td>{item.avg_cost}</td>
                            </tr>
                        ))}
                                </tbody>
                            </Table>
                            </Card>
            </Col>
        </Row>
        
    </div>
  )
}

export default WarehousePage