import axiosAPI from "../axiosApi";

export async function createPledge(payload) {
    const response = await axiosAPI.post("inventory/pledges/", payload);
    return response;
  }

export async function fetchPledges(params = {}) {
  const response = await axiosAPI.get("inventory/pledges/", { params: params });
  return response;
}

export async function fetchPledgeById(id) {
  const response = await axiosAPI.get(`inventory/pledges/${id}/`);
  return response;
}

export async function updatePledgeById(id, payload) {
  const response = await axiosAPI.put(`inventory/pledges/${id}/`, payload);
  return response;
}


export async function deletePledgeById(id) {
    const response = await axiosAPI.delete(`inventory/pledges/${id}/`);
    return response;
}