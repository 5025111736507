import axios from "axios";

// const baseURL = "http://127.0.0.1:8000/api/";
// const baseURL = "/api/";
const baseURL=process.env.REACT_APP_BASE_URL
const accessToken = localStorage.getItem("access_token");

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const axiosAPI = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    Authorization: accessToken ? "Bearer " + accessToken : null,
    "Content-Type": "application/json",
    accept: "application/json"
  }
});

axiosAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (error.response && error.response?.status === 401) {
        window.location.href = "/login/";
        window.location.replace("/login/", true);
        return Promise.reject(error);
    }

    // Prevent infinite loops
    if (error.response && error.response?.status === 403 && error.response?.data.code =='token_not_valid') {

      window.location.href = "/login/";
      window.location.replace("/login/", true);
      return Promise.reject(error);
  }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export function setNewHeaders(response) {
  axiosAPI.defaults.headers["Authorization"] = "Bearer " + response.data.access;
  localStorage.setItem("access_token", response.data.access);
  localStorage.setItem("refresh_token", response.data.refresh);
}

export function setProfileHeaders(response) {
  localStorage.setItem("profile", JSON.stringify(response.data.profile));
}

export default axiosAPI;
