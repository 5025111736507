import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import ErrorAlert from '../../widgets/ErrorAlert';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';
import { createAsset } from '../../../services/market/asset-services';

function InvoiceDiscountForm(props) {
    let id = props.id;
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState({
        "invoice": props.id,
        "title": "",
        "coupon_rate": null,
        "coupon_type": "Fixed",
        "coupon_freq": 1,
        "accrual_day_count": "ACT/ACT"
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        await createAsset(formData).then(response => {
            props.onHide()
            
        }).catch(error => {
            let msg = formatHttpErrorMessage(error);
            setMessage(msg)
        })
      }
  return (
    <>
           <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
            Invoice Discount Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <ErrorAlert>{message}</ErrorAlert>}
            
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" name="title" value={formData.title} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formCouponRate">
                <Form.Label>Discount Rate</Form.Label>
                <Form.Control type="number" name="coupon_rate" value={formData.coupon_rate} onChange={handleChange} />
                </Form.Group>
            </Row>
         
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Coupon type</Form.Label>
                <Form.Control type="text" name="coupon_type" value={formData.coupon_type} onChange={handleChange} readOnly/>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formInvoicePO">
                <Form.Label>Coupon freq</Form.Label>
                <Form.Control type="text" name="coupon_freq" value={formData.coupon_freq} onChange={handleChange} readOnly />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Accrual day count</Form.Label>
                <Form.Control type="text" name="accrual_day_count" value={formData.accrual_day_count} onChange={handleChange} readOnly/>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formInvoiceName">
                <Form.Label>Coupon Type</Form.Label>
                <Form.Control type="text" name="coupon_type" value={formData.coupon_type} onChange={handleChange} readOnly/>
                </Form.Group>
                
            </Row>
            <Row className="mb-3">
                <Col md={12}>
                    <Button variant="success" className='float-end' type="submit">
                        Submit to Market
                    </Button>
                </Col>
            </Row>
            </Form>
            </Modal.Body>
            </Modal>
    </>
  )
}

export default InvoiceDiscountForm