import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { Button, Col, ProgressBar, Row, Table } from 'react-bootstrap';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { formatDate, formatHttpErrorMessage, formatNumber, formatShortDate } from '../../../utils/helper_functions';
import { fetchAssetByInvoiceId, issueAssetById } from '../../../services/market/asset-services';

function NoteDetail(props) {
  const { isLoading, isError, error, data:listings, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["invoice-asset", props.invoice],
      queryFn: () => fetchAssetByInvoiceId(props.invoice),
    });

    const note_status = ["Not Listed","Listed","Closed", "Expired"]

    const issueSelectedNote = async (id) => {
       await issueAssetById(id).then(response => {
         alert("Asset Issued Successfully.")
       }).catch(error => {
         let msg = formatHttpErrorMessage(error);
         alert(msg)
       })
    }
  return (
    <div>
      {isLoading ? (
    <LoadingSpinner />
  ) : isError ? (
    <ErrorAlert>Error: {error.message}</ErrorAlert>
  ) : (<>
      {listings.data.results.map((listing, i) =>(
          <Row className='g-3' key={i}>
            <Col md={12}>
            <ProgressBar now={(listing.pledged/listing.amount )*100} label={`${formatNumber((listing.pledged/listing.amount )*100)}%`} />
            </Col>
          <Col md={4}>
              <Table>
                  <tbody>
                      <tr><th>Amount</th><td>{formatNumber(listing.amount)}</td></tr>
                      <tr><th>Funded</th><td>{formatNumber(listing.pledged)}</td></tr>
                      <tr><th>Term</th><td>{listing.term} Days</td></tr>
                      <tr><th>Maturity date</th><td>{formatDate(listing.maturity_date)}</td></tr>
                  </tbody>
              </Table>
          </Col>
          <Col md={4}>
              <Table>
                  <tbody>
                      <tr><th>Coupon type</th><td>{listing.coupon_type}</td></tr>
                      <tr><th>Coupon freq</th><td>{listing.coupon_freq}</td></tr>
                      <tr><th>Coupon rate</th><td>{listing.coupon_rate}</td></tr>
                      <tr><th>Accrual count</th><td>{listing.accrual_day_count}</td></tr>
                  </tbody>
              </Table>
          </Col>
          <Col md={4}>
              <Table>
                  <tbody>
                      
                      <tr><th>Status</th><td>{note_status[listing.status]}</td></tr>
                      <tr><th>Created</th><td>{formatShortDate(listing.created)}</td></tr>
                      <tr><th>Amount Invested</th><td>{formatNumber(listing.pledged)}</td></tr>
               
                  </tbody>
              </Table>
          </Col>
          <Col md={12}>
                { listing.status == 1 && <Button variant='outline-success' onClick={() => issueSelectedNote(listing.id)} disabled={listing.amount > listing.pledged}>Accept Terms and Confirm Note</Button>}
          </Col>
      </Row>
      ))}
      
  
    </>)}
    </div>
  )
}

export default NoteDetail