import axiosAPI from "../axiosApi";

export async function createInvoice(payload) {
    axiosAPI.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    const response = await axiosAPI.post("vault/invoices/", payload, {headers:{
      'Content-Type': 'multipart/form-data'
    }});
    return response;
  }

export async function fetchInvoices(params = {}) {
  const response = await axiosAPI.get("vault/invoices/", { params: params });
  return response;
}

export async function fetchInvoiceById(id) {
  const response = await axiosAPI.get(`vault/invoices/${id}/`);
  return response;
}

export async function sendInvoiceById(id) {
  const response = await axiosAPI.get(`vault/invoices/${id}/submit_invoice/`);
  return response;
}

export async function updateInvoiceById(id, payload) {
  const response = await axiosAPI.put(`vault/invoices/${id}/`, payload);
  return response;
}


export async function deleteInvoiceById(id) {
    const response = await axiosAPI.delete(`vault/invoices/${id}/`);
    return response;
}

export async function fetchInvoiceSummary() {
  const response = await axiosAPI.get(`vault/invoices/short_summary/`);
  return response;
}