import axiosAPI from "../axiosApi";

export async function createAsset(payload) {
    const response = await axiosAPI.post("vault/assets/", payload);
    return response;
  }

export async function fetchAssets(params = {}) {
  const response = await axiosAPI.get("vault/assets/", { params: params });
  return response;
}

export async function fetchAssetById(id) {
  const response = await axiosAPI.get(`vault/assets/${id}/`);
  return response;
}

export async function fetchAssetByInvoiceId(id) {
  const response = await axiosAPI.get(`vault/assets/?invoice=${id}`);
  return response;
}

export async function approveAssetById(id) {
  const response = await axiosAPI.get(`vault/assets/${id}/list_asset/`);
  return response;
}

export async function issueAssetById(id) {
  const response = await axiosAPI.post(`vault/assets/${id}/issue/`);
  return response;
}

export async function updateAssetById(id, payload) {
  const response = await axiosAPI.put(`vault/assets/${id}/`, payload);
  return response;
}


export async function deleteAssetById(id) {
    const response = await axiosAPI.delete(`vault/assets/${id}/`);
    return response;
}