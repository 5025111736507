import React from 'react'
import { Alert } from 'react-bootstrap'

function ErrorAlert({
  children,
}) {
  return (
    <Alert variant="danger">
          {children}
    </Alert>
  )
}

export default ErrorAlert