import axiosAPI from "../axiosApi";

export async function createBusiness(payload) {
    const response = await axiosAPI.post("accounts/business/", payload);
    return response;
  }

export async function fetchBusinesses(params = {}) {
  const response = await axiosAPI.get("accounts/business/", { params: params });
  return response;
}

export async function fetchBusinessById(id) {
  const response = await axiosAPI.get(`accounts/business/${id}/`);
  return response;
}

export async function updateBusinessById(id, payload) {
  const response = await axiosAPI.put(`accounts/business/${id}/`, payload);
  return response;
}


export async function deleteBusinessById(id) {
    const response = await axiosAPI.delete(`accounts/business/${id}/`);
    return response;
}


export const getCurrentBusiness = async () => {
  let business = JSON.parse(localStorage.getItem("business"));
  console.log(business)
  if(business !== null){
     return business;
  }
  // fetch businesses and set the first one found
  await fetchBusinesses().then(response => {
      business = response.data[0]
      localStorage.setItem("business", JSON.stringify(response.data[0]));
  }).catch(error => {
      console.log("An Error occurred fetching business ")
  })
  return business;
}

export const setCurrentBusiness = async (id) => {
  let business = {}
    // fetch businesses and set the first one found
  await fetchBusinessById(id)
    .then((response) => {
      business = response.data;
      localStorage.setItem("business", JSON.stringify(business));
    })
    .catch((error) => {
      console.log("An Error occurred fetching business ");
    });
  return business;
}