import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import ErrorAlert from '../../widgets/ErrorAlert';
import BusinessFormSelectList from '../../widgets/BusinessFormSelectList';
import {updateInvoiceById } from '../../../services/invoices/invoice-services';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';

function InvoiceEditForm(props) {
    let id = props.id;
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState(props.invoice);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        await updateInvoiceById(id, formData).then(response => {
            props.onHide()
            
        }).catch(error => {
            let msg = formatHttpErrorMessage(error);
            setMessage(msg)
        })
      }
  return (
    <>
           <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
            Invoice Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <ErrorAlert>{message}</ErrorAlert>}
            
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" name="title" value={formData.title} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" name="amount" value={formData.amount} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Issued to</Form.Label>
                <BusinessFormSelectList name="issued_to" value={formData.issued_to} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Invoice No</Form.Label>
                <Form.Control type="text" name="invoice_ref" value={formData.invoice_ref} onChange={handleChange}/>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formInvoicePO">
                <Form.Label>Purchase Order No</Form.Label>
                <Form.Control type="text" name="po_ref" value={formData.po_ref} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Issue date</Form.Label>
                <Form.Control type="date" name="issue_date" value={formData.issue_date} onChange={handleChange}/>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formInvoicePO">
                <Form.Label>Due date</Form.Label>
                <Form.Control type="date" name="due_date" value={formData.due_date} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Col md={12}>
                    <Button variant="primary" className='float-end' type="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
            </Form>
            </Modal.Body>
            </Modal>
    </>
  )
}

export default InvoiceEditForm