import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function InvestorNavLinks() {
  return (
    <>
        <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/">
                Dashboard
              </Nav.Link>
              
              <Nav.Link as={NavLink} to="/market">
                Listings
              </Nav.Link>
              <Nav.Link as={NavLink} to="/warehouse">
                Portofolio
              </Nav.Link>
              <Nav.Link as={NavLink} to="/wallet">
                Wallet
              </Nav.Link>
            </Nav>
    </>
  )
}

export default InvestorNavLinks