import React, { useContext } from "react";
import { Container, Nav, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getUserProfile, logout } from "../../services/auth/auth-service";
import AuthDetailContext from "../../context/AuthDetailContext";
import ClientNavLinks from "./widgets/ClientNavLinks";
import InvestorNavLinks from "./widgets/InvestorNavLinks";

function TopNavBar() {
  const navigate = useNavigate();
  const currentUser = useContext(AuthDetailContext);

  const logoutUser = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await logout();
      //navigate("/login", { replace: true });
      window.location.reload(true);
    }
  };
 
  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            Cash Flow Bob
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-1`} />
          <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-1`}
              aria-labelledby={`offcanvasNavbarLabel-expand-1`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-1`}>
                  MENU
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              {currentUser?.business_type== 'Client' ? <ClientNavLinks /> : <InvestorNavLinks />}
            <Nav>
              <NavItem as={NavLink} onClick={() => logoutUser()} style={{color:'#ffffff'}}>Sign Out({currentUser?.first_name})</NavItem>
            </Nav>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default TopNavBar;
