import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {useNavigate } from 'react-router-dom'
import ErrorAlert from '../../widgets/ErrorAlert';
import BusinessFormSelectList from '../../widgets/BusinessFormSelectList';
import { createInvoice } from '../../../services/invoices/invoice-services';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';

function InvoiceForm(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState();
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        "name": "",
        "invoice_ref": "",
        "po_ref": "",
        "issue_date": null,
        "due_date": null,
        "amount": null,
        "issued_to": null
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
          setSelectedFile(e.target.files[0]);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if ( typeof selectedFile === 'undefined' ) return;
        console.log(selectedFile)
        const myformData = new FormData();
        myformData.append("title", formData.title);
        myformData.append("amount", formData.amount);
        myformData.append("issued_to", formData.issued_to);
        myformData.append("invoice_ref", formData.invoice_ref);
        myformData.append("po_ref", formData.po_ref);
        myformData.append("issue_date", formData.issue_date);
        myformData.append("due_date", formData.due_date);
        myformData.append("file", selectedFile);

        await createInvoice(myformData).then(response => {
            navigate('/invoices/' + response.data.id)
        }).catch(error => {
            let msg = formatHttpErrorMessage(error);
            setMessage(msg)
        })
      }
  return (
    <>
           <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
            Invoice Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <ErrorAlert>{message}</ErrorAlert>}
            
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" name="title" value={formData.title} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" name="amount" value={formData.amount} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Issued to</Form.Label>
                <BusinessFormSelectList name="issued_to" onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Invoice No</Form.Label>
                <Form.Control type="text" name="invoice_ref" value={formData.invoice_ref} onChange={handleChange}/>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formInvoicePO">
                <Form.Label>Purchase Order No</Form.Label>
                <Form.Control type="text" name="po_ref" value={formData.po_ref} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                
                <Form.Group as={Col} md={6} controlId="formInvoiceNo">
                <Form.Label>Issue date</Form.Label>
                <Form.Control type="date" name="issue_date" value={formData.issue_date} onChange={handleChange}/>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formInvoicePO">
                <Form.Label>Due date</Form.Label>
                <Form.Control type="date" name="due_date" value={formData.due_date} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Invoice Copy</Form.Label>
                <Form.Control type="file" name="file" onChange={handleFileChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Col md={12}>
                <div className="d-grid gap-2">
                    <Button variant="primary" className='float-end' type="submit">
                        Upload Invoice
                    </Button>
                </div>
                </Col>
            </Row>
            </Form>
            </Modal.Body>
            </Modal>
    </>
  )
}

export default InvoiceForm