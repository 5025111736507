import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { fetchUserDefaultWarehouse } from '../../../services/inventory/warehouse-services';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { formatNumber } from '../../../utils/helper_functions';

function WarehouseBalance() {
    const { isLoading, isError, error, data:warehouse, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["warehouse"],
      queryFn: () => fetchUserDefaultWarehouse(),
    });
    
  return (
    <div>
      <p>
        <small>
          <strong>Current Investments</strong>
        </small>
      </p>
      {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<><small>UGX</small>{formatNumber(warehouse.data.estimated_value)}</>)}
    </div>
  )
}

export default WarehouseBalance