import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function RegistrationSuccessPage() {
  return (
    <>
        <Container fluid>
            <Row className='m-5'>
                <Col md={{ span: 6, offset: 3 }}>
                  <Card>
                    <Card.Body>
                      <div className='text-center'>
                        <h2 className='mb-5 mt-5'>Welcome to CashFlowBob</h2>
                        <p><Button variant='success' as={Link} to={`/login`}>Sign Into your Account</Button></p>
                        <p>Thank you for joining our community! CashFlowBob is so happy to have you onboard.</p>
                      </div>
                      
                    </Card.Body>
                  </Card>
                    
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default RegistrationSuccessPage