import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { addUser } from '../../services/auth/auth-service';
import { formatHttpErrorMessage } from '../../utils/helper_functions';
import LoadingSpinner from '../widgets/loadingSpinner';

function RegistrationPage() {
  let navigate = useNavigate();
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
          "first_name":"",
          "last_name":"",
          "company":"",
          "email":"",
          "password":"",
          "password2":""
    })

  const handleChange = (event) => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    };

  const handleSubmit = async (event) => {
      event.preventDefault();
      setMessage("");
      setIsLoading(true)
      await addUser(
            formData.company,
            formData.account,
            formData.code,
            formData.email,
            formData.password,
            formData.password2,
            formData.first_name,
            formData.last_name,
        ).then(response => {
          setIsLoading(false)
             navigate('/signup/success', {replace:true})
      }).catch(error => {
          let message = formatHttpErrorMessage(error);
          setMessage(message)
          setIsLoading(false)
      })
    };

  return (
    <>
      <Container className="mt-5">
          <Row>
            <Col md={5} sm={6}>
               <Card>
                  <Card.Header>
                    <Card.Title>Registrations Form</Card.Title>
                  </Card.Header>
                  <Card.Body>
                  {message && <Alert variant='danger'>{message}</Alert>}
                            <Form onSubmit={handleSubmit}>
                              {isLoading && <LoadingSpinner />}
                            <Form.Group className="mb-3" controlId="formCode">
                                    <Form.Label>Invitation Code</Form.Label>
                                    <Form.Control type="text" name="code" placeholder="Enter your invitation code" onChange={handleChange} />
                
                                </Form.Group>
                            <Form.Group className="mb-3" controlId="formCompany">
                                    <Form.Label>Trading Name</Form.Label>
                                    <Form.Control type="text" name="company" placeholder="Enter your company name or trading name" onChange={handleChange} />
                
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCompany">
                                    <Form.Label>I want join as</Form.Label>
                                    <Form.Check type="radio" group='account' name="account" value="Client" label="A Company or Individual" onChange={handleChange} />
                                    <Form.Check type="radio" group='account' name="account"  value="Investor" label="An Investor" onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="first_name" placeholder="First Name" onChange={handleChange} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="last_name" placeholder="Last Name" onChange={handleChange} />
                        
                                </Form.Group>
                                

                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChange} />
                                    <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password" onChange={handleChange}  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword2">
                                    <Form.Label>Repeat Password</Form.Label>
                                    <Form.Control type="password" name="password2" placeholder="Password" onChange={handleChange}  />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                  <Button variant="primary" className='btn-block' type="submit" disabled={isLoading}>
                                      Register Account
                                  </Button>
                                </div>
                                
                            </Form>
                  </Card.Body>
               </Card>
            </Col>
          </Row>
      </Container>
    </>
  )
}

export default RegistrationPage