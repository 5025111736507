import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout, obtainToken } from '../../services/auth/auth-service';
import { formatHttpErrorMessage } from '../../utils/helper_functions';

function LoginPage() {
    let navigate = useNavigate();
    const [message, setMessage] = useState();
    const location = useLocation();
    const [formData, setFormData] = useState({
        "email":"",
        "password":""
    })
    
    const from = location.state?.from?.pathname || "/";

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage("");
        if(isAuthenticated()){
            logout()
        }
        await obtainToken(formData['email'], formData['password']).then(response => {
               
               window.location.replace(from, false);
              // navigate(from, { replace: true });
        }).catch(error => {
            let message = formatHttpErrorMessage(error)
            setMessage(message)
        })
      };

  return (
    <>
        <Container fluid  className="mt-5">
            <Row>
                <Col lg={{span:4, offset:4}} md={{span:8, offset:2}} sm={12} className="mt-5">
                   
                    <Card>
                        <Card.Header>
                            <Card.Title>Sign In</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        {message && <Alert variant='danger'>{message}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="Enter email" onChange={handleChange} />
                                    <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password" onChange={handleChange}  />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="submit">
                                    Sign In
                                </Button>
                                </div>
                                
                            </Form>
                            <p className='mt-3'>
                                <Link to="/signup">Sign Up</Link>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default LoginPage