import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import InvoiceList from './widgets/InvoiceList'
import InvoiceForm from './widgets/InvoiceForm'

function InvoicePage() {
    const [showInvoiceForm, setShowInvoiceForm] = useState(false)
  return (
    <>
        <Row className='mb-3 mt-3'>
            <Col md={6}>
                <h2>My Invoices</h2>
            </Col>
            <Col md={6} className=''>
                <Button variant='outline-primary' onClick={() => setShowInvoiceForm(true)} className='float-end'>Add Invoice</Button>
                {showInvoiceForm && <InvoiceForm show={showInvoiceForm} onHide={() => setShowInvoiceForm(false)} />}
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <Card>
                    <Card.Body>
                        <Row className='mb-2'>
                            <Col md={8}>
                                
                            </Col>
                            <Col md={4}>
                                <Form.Control type='text' placeholder='Search invoices' />
                            </Col>
                        </Row>
                        <InvoiceList />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default InvoicePage