import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import ErrorAlert from '../../widgets/ErrorAlert'
import { createPledge } from '../../../services/inventory/pledge-services';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';
import WarehouseFormList from './WarehouseFormList';

function PledgeForm(props) {
    let id = props.id;
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState({
        "amount": props.listing.amount - props.listing.pledged,
        "asset": props.id,
        "warehouse": null
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        await createPledge(formData).then(response => {
            props.onSuccess()
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg)
        })
    }
  return (
    <>
         <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
            Pledge Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <ErrorAlert>{message}</ErrorAlert>}
            
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formTitle">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" name="amount" value={formData.amount} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formCouponRate">
                <Form.Label>Warehouse</Form.Label>
                <WarehouseFormList type="number" name="warehouse" value={formData.warehouse} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Col md={12}  className="d-grid gap-2">
                    <Button variant="success" type="submit">
                        Submit Pledge
                    </Button>
                </Col>
            </Row>
            </Form>
            </Modal.Body>
            </Modal>
    </>
  )
}

export default PledgeForm