import axiosAPI from "../axiosApi";

export async function createBill(payload) {
    const response = await axiosAPI.post("vault/bills/", payload);
    return response;
  }

export async function fetchBills(params = {}) {
  const response = await axiosAPI.get("vault/bills/", { params: params });
  return response;
}

export async function fetchBillById(id) {
  const response = await axiosAPI.get(`vault/bills/${id}/`);
  return response;
}

export async function updateBillById(id, payload) {
  const response = await axiosAPI.put(`vault/bills/${id}/`, payload);
  return response;
}

export async function approveBillById(id, payload) {
  const response = await axiosAPI.post(`vault/bills/${id}/approve/`, payload);
  return response;
}


export async function deleteBillById(id) {
    const response = await axiosAPI.delete(`vault/bills/${id}/`);
    return response;
}