import React, { useState } from 'react'
import { Badge } from 'react-bootstrap'

function RatingBadge(props) {
  let x = "secondary";
  if (props.rating == "A") {
    x = "success";
  } else if (props.rating == "B") {
    x = "primary";
  } else if (props.rating == "C") {
    x = "info";
  } else if (props.rating == "D") {
    x = "warning";
  } else if (props.rating == "E") {
    x = "danger";
  }

  return (
    <div>
      <Badge bg={x}>{props.rating}</Badge>
    </div>
  );
}

export default RatingBadge