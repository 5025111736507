import React from 'react'
import ErrorAlert from './ErrorAlert'
import { Form } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query';
import { fetchBusinesses } from '../../services/auth/business-service';

function BusinessFormSelectList(props) {
  const { isLoading, isError, error, data:providers, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["business-list"],
      queryFn: () => fetchBusinesses({...props.filter}),
      keepPreviousData: true
    });
  return (
    <>
    {isLoading ? (<>Loading</>) : isError ? (
          <ErrorAlert>Error: {error.message}</ErrorAlert>
        ) : (
      <Form.Select aria-label="Default select Partner"  {...props} >
                  <option value="">Choose Partner</option>
                  {providers.data.results.map((item, i) => (
                      <option value={item.id} key={i}>{item.name} </option>
                  ))}
              </Form.Select>
        )}
  </>
  )
}

export default BusinessFormSelectList