import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { Button, Card, Col, ProgressBar, Row, Stack, Table } from 'react-bootstrap'
import { approveAssetById, fetchAssetById } from '../../services/market/asset-services';
import LoadingSpinner from '../widgets/loadingSpinner';
import ErrorAlert from '../widgets/ErrorAlert';
import { useParams } from 'react-router-dom';
import PledgeForm from './widgets/PledgeForm';
import { formatDate, formatHttpErrorMessage, formatNumber, formatShortDate } from '../../utils/helper_functions';

function ListingDetailPage() {
    let {id} = useParams()
    const queryClient = useQueryClient();
    const [showPledgeForm, setShowPledgeForm] = useState(false)
    const { isLoading, isError, error, data:listing, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["assets", id],
      queryFn: () => fetchAssetById(id),
    });

    const approveListing = async () => {
        await approveAssetById(id).then(response => {
            alert("item submitted to Market");
            queryClient.invalidateQueries();
        }).catch(error =>{
            let msg = formatHttpErrorMessage(error)
        })
    }

    const onInvestSuccess = () => {
        setShowPledgeForm(false)
        alert("Investment Successfull!")
        queryClient.invalidateQueries();
    }
  return (
    <>
        {isLoading ? (
            <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (
            <>
        <Row className='g-3 mt-3'>
            <Col md={6}>
                <h4>Coupon Details</h4>
            </Col>
            <Col md={6}>
                <Stack direction="horizontal" gap={3} className='float-end'>
                    {listing.data.status==1 && <Button variant='success' onClick={() => setShowPledgeForm(true)} disabled={listing.data.amount==listing.data.pledged}>Invest</Button>}
                    {listing.data.status==0 && <Button variant='primary' onClick={() => approveListing()}>Submit to Market</Button>}
                </Stack>
                {showPledgeForm && <PledgeForm id={id} listing={listing.data} show={showPledgeForm} onHide={() => setShowPledgeForm(false)} onSuccess={onInvestSuccess} />}
            </Col>
            
            <Col md={12}>
                
                <Card>
                    <Card.Body>
                    
                        <Row className='g-3'>
                            <Col md={12}>
                                <ProgressBar now={(listing.data.pledged/listing.data.amount )*100} label={`${formatNumber((listing.data.pledged/listing.data.amount )*100)}%`} />
                            </Col>
                            <Col md={4}>
                                <Table>
                                    <tbody>
                                        
                                        <tr><th>Amount</th><td>{listing.data.amount}</td></tr>
                                        <tr><th>Face Value</th><td>{listing.data.face_value}</td></tr>
                                        <tr><th>Coupon rate</th><td>{listing.data.coupon_rate}</td></tr>
                                        <tr><th>Maturity date</th><td>{formatDate(listing.data.maturity_date)}</td></tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Table>
                                    <tbody>
                                        <tr><th>Coupon type</th><td>{listing.data.coupon_type}</td></tr>
                                        <tr><th>Coupon freq</th><td>{listing.data.coupon_freq}</td></tr>
                                        <tr><th>Coupon rate</th><td>{listing.data.coupon_rate}</td></tr>
                                        <tr><th>Accrual count</th><td>{listing.data.accrual_day_count}</td></tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Table>
                                    <tbody>
                                        
                                        <tr><th>Status</th><td>{listing.data.status =='0' ? "Not Listed" :'Listed'}</td></tr>
                                        <tr><th>Created</th><td>{formatShortDate(listing.data.created)}</td></tr>
                                        <tr><th>Amount Invested</th><td>{listing.data.pledged}</td></tr>
                                 
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={12}>
                <h5>Guarantor Information</h5>
                <Card>
                    <Card.Body>
                        Name: {listing.data.invoice.issued_to.name}<br/>
                        Rating: {listing.data.invoice.issued_to.rating}
                    </Card.Body>
                </Card>
            </Col>
            <Col md={12}>
                <h5>Issuer Information</h5>
                <Card>
                    <Card.Body>
                    Name: {listing.data.invoice.issuer.name}<br/>
                    Rating: {listing.data.invoice.issuer.rating}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>)}
    </>
  )
}

export default ListingDetailPage