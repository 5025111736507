import React, { useState } from 'react'
import { fetchBillById } from '../../services/invoices/bill-services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../widgets/loadingSpinner';
import ErrorAlert from '../widgets/ErrorAlert';
import { Button, Card, Col, Row, Stack, Tab, Table, Tabs } from 'react-bootstrap';
import { formatDate, formatShortDate } from '../../utils/helper_functions';
import BillApprovalForm from './widgets/BillApprovalForm';
import AttachmentTableList from '../invoices/widgets/AttachmentTableList';

function BillDetailPage() {
    let {id} = useParams()
    const queryClient = useQueryClient();
    const [showApprovalForm, setShowApprovalForm] = useState(false)
    const { isLoading, isError, error, data:invoice, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["bill", id],
      queryFn: () => fetchBillById(id),
    });

    const handleApprovalSuccess = () => {
      setShowApprovalForm(false)
      alert("Bill Approved Successfully!")
      queryClient.invalidateQueries();
    }
  return (
    <>
      <Row className='g-3'>
        <Col md={12}>
        <Stack direction="horizontal" gap={2}>
            <Button variant='success' onClick={() => setShowApprovalForm(true)} disabled={invoice?.data.status !='Pending'}>Approve</Button>
            <Button variant='info'>Make Payment</Button>
            <Button variant='danger' disabled={invoice?.data.status !='Pending'}>Reject</Button>
        </Stack>
        {showApprovalForm && <BillApprovalForm id={id} show={showApprovalForm} onHide={() => setShowApprovalForm(false)} onSuccess={handleApprovalSuccess} />}
        </Col>
        <Col>
          {isLoading ? (
            <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (
            <>
              <Card>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Invoice</th><td>{invoice.data.invoice_ref}</td></tr>
                                <tr><th>Amount</th><td>{invoice.data.amount}</td></tr>
                                <tr><th>Purchase Ref</th><td>{invoice.data.po_ref}</td></tr>
                                <tr><th>Vendor</th><td>{invoice.data.issuer.name}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Date Issued</th><td>{formatDate(invoice.data.issue_date)}</td></tr>
                                <tr><th>Due date</th><td>{formatDate(invoice.data.due_date)}</td></tr>
                                <tr><th>Pay date</th><td>{formatDate(invoice.data.pay_date)}</td></tr>
                                <tr><th>Date paid</th><td>{formatDate(invoice.data.date_paid)}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table>
                                <tbody>
                                <tr><th>Status</th><td>{invoice.data.status}</td></tr>
                                <tr><th>Updated</th><td>{formatShortDate(invoice.data.updated)}</td></tr>
                                <tr><th>Created</th><td>{formatShortDate(invoice.data.created)}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
              </Card>
            </>
          )}
        </Col>
         <Col md={12}>
        <Card className="mt-1">
          <Card.Body>
                    <Tabs
                      defaultActiveKey="home"
                      id="detail-tab"
                      className="mb-3"
                    >
                    <Tab eventKey="home" title="Payments" className='p-3'>
                       
                    </Tab>
                    <Tab eventKey="Attachments" title="Attachments" className='p-3'>
                      <AttachmentTableList invoice={id}/>
                    </Tab>

                    </Tabs>
                    </Card.Body>
            </Card>
     </Col>
      </Row>
    </>
  );
}

export default BillDetailPage