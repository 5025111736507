import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import ErrorAlert from '../../widgets/ErrorAlert';
import { approveBillById } from '../../../services/invoices/bill-services';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';

function BillApprovalForm(props) {
    let id = props.id;
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState({
        "bill": props.id,
        "pay_date": null,
        "notes": ""
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        await approveBillById(id, formData).then(response => {
            props.onSuccess()
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg)
        })
      
      }
  return (
    <>
          <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
            Invoice Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <ErrorAlert>{message}</ErrorAlert>}
            
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Pay date</Form.Label>
                <Form.Control type="date" name="pay_date" value={formData.pay_date} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="formInvoiceName">
                <Form.Label>Notes</Form.Label>
                <Form.Control type="text" name="notes" value={formData.notes} onChange={handleChange} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Col md={12}>
                    <Button variant="primary" className='float-end' type="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
            </Form>
            </Modal.Body>
            </Modal>
    </>
  )
}

export default BillApprovalForm