import React from 'react'
import { Col, Nav, Row } from 'react-bootstrap'

function WalletSettingsPage() {
  return (
    <>
        <Row>
            <Col>
                <h3>Settings</h3>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
            <Nav defaultActiveKey="/home" className="flex-column">
      <Nav.Link href="/linked_accounts">Linked Accounts</Nav.Link>
      <Nav.Link eventKey="link-1">Link</Nav.Link>
      <Nav.Link eventKey="link-2">Link</Nav.Link>
      <Nav.Link eventKey="disabled" disabled>
        Disabled
      </Nav.Link>
    </Nav>
            </Col>
            <Col md={8}>
                Items go here
            </Col>
        </Row>
    </>
  )
}

export default WalletSettingsPage