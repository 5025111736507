import React, { useState } from 'react'
import { fetchInvoices } from '../../../services/invoices/invoice-services';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from '../../../utils/helper_functions';
import StatusBadge from '../../widgets/StatusBadge';

function InvoiceList(props) {
    
    const { isLoading, isError, error, data:invoices, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["invoice-list"],
      queryFn: () => fetchInvoices({ ...props.filters }),
      keepPreviousData: false
    });
  return (
    <>
        {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<>
                <Table hover striped responsive>
                  <thead>
                    <tr>
                        <th>#</th>
                        <th>InvoiceNo</th>
                        <th>Amount</th>
                        <th>Issue Date</th>
                        <th>Customer</th>
                        
                        <th>Due Date</th>
                        <th>PayDate</th>
                        <th>Status</th>
                        
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.data.results.map((item, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                                <Link to={`${item.id}`}>{item.invoice_ref}</Link>
                            </td>
                            <td>{formatNumber(item.amount)}</td>
                            <td>{formatDate(item.issue_date)}</td>
                            <td>{item.issued_to.name}</td>
                            
                            <td>{formatDate(item.due_date)}</td>
                            <td>{formatDate(item.pay_date)}</td>
                            <td><StatusBadge value={item.status}/></td>
                            
                        </tr>
                    ))}
                  </tbody>
                  </Table>
                </>)}
    </>
  )
}

export default InvoiceList