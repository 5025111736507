import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import BillList from './widgets/BillList'

function BillsPage() {
  return (
    <>
      <Row className='mb-3 mt-3'>
            <Col md={6}>
                <h2>My Bills</h2>
            </Col>
            <Col md={6} className=''>
                
               
            </Col>
        </Row>
       <Row>
        <Col md={12}>
            <Card>
                <Card.Body>
                <Row className='mb-2'>
                            <Col md={8}>
                                
                            </Col>
                            <Col md={4}>
                                <Form.Control type='text' placeholder='Search bills' />
                            </Col>
                        </Row>
                    <BillList />
                </Card.Body>
            </Card>
        </Col>
       </Row>
    </>
  )
}

export default BillsPage