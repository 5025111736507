import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { fetchPrimaryAccount } from '../../../services/wallet/account-services';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { formatNumber } from '../../../utils/helper_functions';

function WalletBalance() {
    const { isLoading, isError, error, data:wallet, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["wallet-primary"],
      queryFn: () => fetchPrimaryAccount(),
    });
  return (
    <div>
      <p>
        <small>
          <strong>Wallet Balance</strong>
        </small>
      </p>
      {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<><small>UGX</small>{formatNumber(wallet.data.balance)}</>)}
    </div>
  );
}

export default WalletBalance