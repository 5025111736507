import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { Form } from 'react-bootstrap';
import { fetchLinkedAccount } from '../../../services/wallet/linked-account-service';

function LinkedAccountSelect(props) {
    const { isLoading, isError, error, data:accounts, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["linked-accounts"],
      queryFn: () => fetchLinkedAccount(),
    });
  return (
    <>
        <Form.Select aria-label="Select linked account" {...props}>
        
        {isLoading ? (
            <option>Loading ...</option>
          ) : isError ? (
            <option>Error ....</option>
          ) : (<><option value="">Select</option>
          {accounts.data.results.map((item, i) => (<option value={item.id} key={i}>{item.category} - {item.account_no}</option>))}
                            </>)}
          </Form.Select>
    </>
  )
}

export default LinkedAccountSelect