import React, { useContext, useState } from 'react'
import { Button, Card, Col, Row, Stack, Table } from 'react-bootstrap'
import IncomeHistoryBarChart from './widgets/IncomeHistoryBarChart'
import AuthDetailContext from '../../context/AuthDetailContext';
import InvoiceForm from '../invoices/widgets/InvoiceForm';
import { useQuery } from '@tanstack/react-query';
import WalletBalance from './widgets/WalletBalance';
import { fetchInvoiceSummary } from '../../services/invoices/invoice-services';
import LoadingSpinner from '../widgets/loadingSpinner';
import ErrorAlert from '../widgets/ErrorAlert';
import { formatNumber } from '../../utils/helper_functions';

function ClientDashboard() {
    const [showInvoiceForm, setShowInvoiceForm] = useState(false)
  const currentUser = useContext(AuthDetailContext);
  const { isLoading, isError, error, data:invoice_summary, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["invoice-summary"],
      queryFn: () => fetchInvoiceSummary(),
    });
  
  return (
    <>
      <Row className="g-3 mb-3 mt-2">
        <Col>
          <h2>Welcome {currentUser?.first_name}, </h2>
        </Col>
        <Col md={6}>
        <Stack direction="horizontal" gap={3} className="float-end">
          <Button
            variant="outline-primary"
            onClick={() => setShowInvoiceForm(true)}
          >
            Upload Invoice
          </Button>
          <Button
            variant="outline-success"
            onClick={() => setShowInvoiceForm(true)}
          >
            My Profile
          </Button>
          </Stack>
          {showInvoiceForm && (
            <InvoiceForm
              show={showInvoiceForm}
              onHide={() => setShowInvoiceForm(false)}
            />
          )}
        </Col>
      </Row>
      <Row className="g-3">
        <Col md={12}>
          <Card>
            <Card.Body>
              <Row>
              {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<>
                {invoice_summary.data && <><Col md={4} className="text-left">
                  <p>
                    <small>
                      <strong>Overdue</strong>
                    </small>
                  </p>
                  <small>UGX</small> {invoice_summary.data.pending.total_amount ? formatNumber(invoice_summary.data.pending.total_amount) : 0}
                </Col>
                <Col md={4} className="text-center">
                  <p>
                    <small>
                      <strong>Due within next 30 days</strong>
                    </small>
                  </p>
                  <small>UGX</small> {invoice_summary.data.active.total_amount ? formatNumber(invoice_summary.data.active.total_amount):0}
                </Col></>}
                </>)}
                <Col md={4} className="text-center">
                  <div className="float-end">
                    <WalletBalance />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col md={8}>
          <IncomeHistoryBarChart />
        </Col>
        <Col md={4}>
          <small>
            <strong>Invoices payable to you</strong>
          </small>
          <Table>
            <tbody>
              <tr>
                <td>1-30 days</td>
                <td>UGX 500,000</td>
              </tr>
              <tr>
                <td>31-60 days</td>
                <td>UGX 800,000</td>
              </tr>
              <tr>
                <td>61-90 days</td>
                <td>UGX 1,500,000</td>
              </tr>
              <tr>
                <td> {`>`} 90 days </td>
                <td>UGX 16,500,000</td>
              </tr>
            </tbody>
          </Table>
          <small>
            <strong>Bills you owe</strong>
          </small>
          <Table>
            <tbody>
              <tr>
                <td>1-30 days</td>
                <td>UGX 200,000</td>
              </tr>
              <tr>
                <td>31-60 days</td>
                <td>UGX 200,000</td>
              </tr>
              <tr>
                <td>61-90 days</td>
                <td>UGX 2,600,000</td>
              </tr>
              <tr>
                <td> {`>`} 90 days </td>
                <td>UGX 8,500,000</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default ClientDashboard