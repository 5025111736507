import axiosAPI from "../axiosApi";

export async function createWithdrawRequest(payload) {
    const response = await axiosAPI.post("wallet/channels/withdraws/", payload);
    return response;
  }

export async function fetchWithdrawRequest(params = {}) {
  const response = await axiosAPI.get("wallet/channels/withdraws/", { params: params });
  return response;
}

export async function fetchWithdrawRequestById(id) {
  const response = await axiosAPI.get(`wallet/channels/withdraws/${id}/`);
  return response;
}

export async function updateWithdrawRequestById(id, payload) {
  const response = await axiosAPI.put(`wallet/channels/withdraws/${id}/`, payload);
  return response;
}


export async function deleteWithdrawRequestById(id) {
    const response = await axiosAPI.delete(`wallet/channels/withdraws/${id}/`);
    return response;
}