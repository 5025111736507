import React, { useContext, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import IncomeHistoryBarChart from './dashboard/widgets/IncomeHistoryBarChart';
import InvoiceForm from './invoices/widgets/InvoiceForm';
import AuthDetailContext from '../context/AuthDetailContext';
import InvestorDashboard from './dashboard/InvestorDashboard';
import ClientDashboard from './dashboard/ClientDashboard';

function DashboardPage() {
  const [showInvoiceForm, setShowInvoiceForm] = useState(false)
  const currentUser = useContext(AuthDetailContext);
  return (
    <>
      {currentUser?.business_type == "Investor" ? (
        <InvestorDashboard />
      ) : (
        <ClientDashboard />
      )}
    </>
  );
}

export default DashboardPage