import axiosAPI from "../axiosApi";

export async function createLinkedAccount(payload) {
    const response = await axiosAPI.post("wallet/channels/linked_accounts/", payload);
    return response;
  }

export async function fetchLinkedAccount(params = {}) {
  const response = await axiosAPI.get("wallet/channels/linked_accounts/", { params: params });
  return response;
}

export async function fetchLinkedAccountById(id) {
  const response = await axiosAPI.get(`wallet/channels/linked_accounts/${id}/`);
  return response;
}

export async function updateLinkedAccountById(id, payload) {
  const response = await axiosAPI.put(`wallet/channels/linked_accounts/${id}/`, payload);
  return response;
}


export async function deleteLinkedAccountById(id) {
    const response = await axiosAPI.delete(`wallet/channels/linked_accounts/${id}/`);
    return response;
}