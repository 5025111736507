import React from 'react'
import { Badge } from 'react-bootstrap'

function StatusBadge(props) {
    let color = "secondary";
    if (props.value == 'Success' || props.value == 'Approved'){
        color="success"
    }else if (props.value == 'Sent'){
        color="primary"
    }else if (props.value == 'Rejected' || props.value == 'Failed'){
        color="danger"
    }else if(props.value =='Pending'){
        color='warning'
    }else if(props.value =='Paid'){
        color='info'
    }
  return (
    <Badge bg={color}>{props.value}</Badge>
  )
}

export default StatusBadge