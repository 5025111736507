import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { Form } from 'react-bootstrap';
import ErrorAlert from '../../widgets/ErrorAlert';
import { fetchWarehouses } from '../../../services/inventory/warehouse-services';

function WarehouseFormList(props) {
    const { isLoading, isError, error, data:warehouse, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["warehouse-list"],
      queryFn: () => fetchWarehouses(),
    });

  return (
    <>
        {isLoading ? (<>Loading</>) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (
                <Form.Select aria-label="Select Warehouse"  {...props} >
                    <option value="">Choose Warehouse</option>
                    {warehouse.data.results.map((item, i) => (
                        <option value={item.id} key={i}>{item.name} </option>
                    ))}
                </Form.Select>
          )}
    </>
  )
}

export default WarehouseFormList