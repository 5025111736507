import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TopNavBar from './TopNavBar'


function UserLayout({ children, }) {
  return (
    <div>
      <TopNavBar />
      <Container>
        <Row className='g-3'>
          <Col xs={12} className="pt-3">
           {children}
          </Col>
        </Row>
      </Container>
     </div>
  )
}

export default UserLayout