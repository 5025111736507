import axiosAPI from "../axiosApi";

export async function createAccount(payload) {
    const response = await axiosAPI.post("wallet/accounts/", payload);
    return response;
  }

export async function fetchAccounts(params = {}) {
  const response = await axiosAPI.get("wallet/accounts/", { params: params });
  return response;
}

export async function fetchAccountById(id) {
  const response = await axiosAPI.get(`wallet/accounts/${id}/`);
  return response;
}

export async function fetchPrimaryAccount() {
    const response = await axiosAPI.get(`wallet/accounts/primary/`);
    return response;
  }


export async function updateAccountById(id, payload) {
  const response = await axiosAPI.put(`wallet/accounts/${id}/`, payload);
  return response;
}

export async function fetchWalletStatement(id) {
  const response = await axiosAPI.get(`wallet/accounts/${id}/min_statement/`);
  return response;
}