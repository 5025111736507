import React from 'react'
import { fetchAttachments } from '../../../services/invoices/attachment-services';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../widgets/loadingSpinner';
import ErrorAlert from '../../widgets/ErrorAlert';
import { Table } from 'react-bootstrap';
import { formatShortDate } from '../../../utils/helper_functions';

function AttachmentTableList(props) {
    const { isLoading, isError, error, data:attachments, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["invoice-attachments", props.invoice],
      queryFn: () => fetchAttachments(props.invoice),
      keepPreviousData: false
    });

  return (
    <div>
        {isLoading ? (
        <LoadingSpinner />
          ) : isError ? (
            <ErrorAlert>Error: {error.message}</ErrorAlert>
          ) : (<>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {attachments.data.results.map((item, i) => (
                        <tr>
                            <td>{i+1}</td>
                            <td><a href={item.file}>{item.title}</a></td>
                            <td>{item.author}</td>
                            <td>{formatShortDate(item.created)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
          </>)}

    </div>
  )
}

export default AttachmentTableList