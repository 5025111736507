import React, { useContext, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import IncomeHistoryBarChart from './widgets/IncomeHistoryBarChart'
import AuthDetailContext from '../../context/AuthDetailContext';
import InvestmentHistoryLineChart from './widgets/InvestmentHistoryLineChart';
import WalletBalance from './widgets/WalletBalance';
import WarehouseBalance from './widgets/WarehouseBalance';
import ActiveNotes from './widgets/ActiveNotes';

function InvestorDashboard() {
   const [showInvoiceForm, setShowInvoiceForm] = useState(false)
  const currentUser = useContext(AuthDetailContext);
  
  return (
    <>
      <Row className="g-3 mb-3 mt-2">
        <Col>
          <h2>Welcome {currentUser?.first_name}, </h2>
        </Col>
        <Col md={6}>
          <Button
            variant="outline-success"
            onClick={() => setShowInvoiceForm(true)}
            className="float-end"
          >
            Deposit
          </Button>
          
        </Col>
      </Row>
      <Row className="g-3">
        <Col md={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={4} className="text-left">
                  <WarehouseBalance />
                </Col>
                <Col md={4} className="text-center">
                   <ActiveNotes />
                </Col>
                <Col md={4} className="text-center">
                  <div className="float-end">
                    <WalletBalance />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col md={8}>
          <InvestmentHistoryLineChart />
        </Col>
        <Col md={4}>
          <small>
            <h5>Assets Maturing</h5>
          </small>
          <Table>
            <tbody>
              <tr>
                <td>1-30 days</td>
                <td>UGX 500,000</td>
              </tr>
              <tr>
                <td>31-60 days</td>
                <td>UGX 800,000</td>
              </tr>
              <tr>
                <td>61-90 days</td>
                <td>UGX 1,500,000</td>
              </tr>
              <tr>
                <td> {`>`} 90 days </td>
                <td>UGX 16,500,000</td>
              </tr>
            </tbody>
          </Table>
          <small>
            <h5>Popular Listings</h5>
          </small>
          <Table>

          </Table>
        </Col>
      </Row>
    </>
  );
}

export default InvestorDashboard